<template>
<ul class="list-info">
  <li>
    <strong>{{ checkins }}</strong>
    <br />{{ $t('components.UserStats.checkins')}}
  </li>
  <li>
    <strong>{{ points }}</strong> <small>pts</small>
    <br />{{$t('components.UserStats.balance')}}
  </li>
  <li>
    <strong>{{ distance }}</strong> <small>km</small>
    <br />{{ $t('components.UserStats.distance')}}
  </li>
</ul>
</template>
<script>
export default {
  props: {
    points: {
      type: Number,
      required: true,
    },
    checkins: {
      type: Number,
      required: true,
    },
    distance: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>
