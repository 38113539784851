<template>
  <section class="section">
    <div
      v-if="destinationLoaded"
      class="container"
    >
      <log-detail
        v-if="openLogModal === true"
        :is-active="openLogModal"
        :log="currentLog"
        :pois="poisByUUID"
        @close="toggleLogModal(null)"
      />
      <div class="columns">
        <div class="column is-flex is-horizontal-center">
          <figure>
            <!-- eslint-disable -->
          <img
            :src="`/themes/${appConfig.cssTheme}/images/logo.png`"
            :srcset="`/themes/${appConfig.cssTheme}/images/logo@2x.png 2x,/themes/${appConfig.cssTheme}/images/logo@3x.png 3x`"
             alt="site logo" />
          <!-- eslint-enable -->
          </figure>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <back-button
            :title="$t('common.back_button')"
            @click="$router.push('/')"
          />
        </div>
      </div>
      <div class="columns">
      <!--<div class="column is-flex is-horizontal-center">
        <figure class="is-128x128 profile-picture">
          <span class="icon is-large">
            <i class="fas fa-camera is-white"></i>
          </span>
        </figure>
      </div> -->
      </div>
      <div class="columns">
        <div class="column account_info has-text-centered">
          <h4 v-if="user">
            {{ currentTeam.name }}
          </h4>
          <a
            class="hast-text-centered"
            href="#"
          >
          <!--<router-link v-if="appConfig.useGameApi === false" :to="{ name: 'Edit Account'}">
            {{ $t('views.Dashboard.edit_information_text')}}
          </router-link> -->
          </a>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <user-stats
            v-if="user"
            :points="currentTeamScore"
            :checkins="currentTeamCheckinsCount"
            :distance="currentTeamDistance"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h3>{{ $t('views.Dashboard.history_title') }}</h3>
          <p
            v-if="teamLog.length === 0"
            class="has-text-centered"
          >
            {{ $t('views.Dashboard.history_text') }}
          </p>
          <div v-if="teamLog.length > 0">
            <ul class="locations">
              <team-log-item
                v-for="i in teamLog"
                :key="i.id"
                :item="i"
                :pois="poisByUUID"
                @open="toggleLogModal(i)"
              />
            </ul>
          </div>
          <div
            v-else
            class="has-text-centered"
          >
            <p> {{ $t('views.Dashboard.history_text') }}</p>
            <a
              href="/"
              class="btn btn--outline btn--wide"
            >
              {{ $t('views.Dashboard.start_visiting') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import haversine from 'haversine';
import BackButton from '../components/account/BtnBack.vue';
import UserStats from '../components/account/UserStats.vue';
import TeamLogItem from '../components/account/TeamLogItem.vue';
import LogDetail from '../components/account/LogDetail.vue';

import { useUIStore } from '../pinia/uistate';
import { useGameStore } from '../pinia/game';
import { useDestinationStore } from '../pinia/destination';
import { usePoiStore } from '../pinia/poi';

export default {
  components: {
    UserStats,
    TeamLogItem,
    BackButton,
    LogDetail,
  },
  setup() {
    const appConfig = useUIStore().appConfig;
    const gameStore = useGameStore();
    const destinationStore = useDestinationStore();
    const poiStore = usePoiStore();
    return { appConfig, gameStore, destinationStore, poiStore};
  },
  data() {
    return {
      modal: false,
      currentLog: null,
      openLogModal: false,
    };
  },
  computed: {
    checkinsCount() {
      return this.history.filter((i) => i.type === 'checkin').length;
    },
    t() {
      return 1;
    },
    currentTeam() {
      return this.gameStore.currentTeam;
    },
    teamLog() {
      return this.gameStore.teamLogs;
    },
    user() {
      return this.gameStore.currentToken;
    },
    currentToken() {
      return this.gameStore.currentToken;
    },
    destination() {
      return this.destinationStore.currentDestination;
    },
    destinationLoaded() {
      return this.destinationStore.currentDestination !== null;
    },
    pois() {
      return this.poiStore.activePois
    },

    fullHistory() {
      return this.gameStore.currentTeam?.events;
    },


    history() {
      return [];
    },

    // User score (legacy)
    userDistance() {
      return 0.0
      /*
      const rawDistance = this.$store.getters[gt.USER_DISTANCE];
      return parseInt(rawDistance / 1000, 10);
      */
    },

    // Team score
    currentTeamScore() {
      let score = 0;
      this.teamLog.forEach((l) => {
        score += l.score;
      });
      return score;
    },
    currentTeamCheckinsCount() {
      let c = 0;
      this.teamLog.forEach((l) => {
        if (l.type === 'checkin') {
          c += 1;
        }
      });
      return c;
    },
    currentTeamDistance() {
      const lngLats = [];
      this.teamLog.forEach((l) => {
        if (l.type === 'checkin') {
          lngLats.push({ latitude: l.content.teamLatitude, longitude: l.content.teamLongitude });
        }
      });
      if (lngLats.length < 2) {
        return 0;
      }
      let distance = 0;
      let lastPlace = null;
      lngLats.forEach((c) => {
        if (lastPlace !== null) {
          distance += haversine(
            [lastPlace.longitude, lastPlace.latitude],
            [c.place.longitude, c.place.latitude],
            { format: '[lon,lat]', unit: 'meter' },
          );
          lastPlace = c.place;
        }
      });
      return distance;
    },
    poisByUUID() {
      const res = {};
      this.pois.forEach((p) => {
        res[p.uuid] = p;
      });
      return res;
    },
    // Legacy history
    unifiedHistory() {
      const unifiedArray = [];
      this.history.forEach((item) => {
        const content = {
          name: '',
          description: '',
          points: item.points,
          operator: item.operator,
          picture: '',
          type: item.type,
        };
        let loc = null;
        switch (item.type) {
          case 'checkin':
            loc = this.translatePlace(item);

            content.name = loc ? loc.name : item.name ? item.name : 'archive';
            content.description = loc ? loc.description : 'archive';
            content.picture = this.img(item.place.picture, 'places');
            unifiedArray.push(content);
            break;
          default:
            break;
        }
      });
      return unifiedArray;
    },
  },
  async mounted() {
    await this.gameStore.fetchTeamLogs();
  },
  methods: {
    toggleLogModal(log) {
      this.currentLog = log;
      this.openLogModal = !this.openLogModal;
    },
    img(pictures) {
      if (!pictures || pictures.length === 0) {
        return 'default.jpg';
      }
      return pictures[0].url;
    },
    translatePlace(p) {
      if (!p.place.locales || p.place.locales.length === 0) {
        return {
          name: p.name,
          description: '',
        };
      }
      const lang = this.$i18n.locale;
      const locale = p.place.locales.filter((l) => {
        if (l.locale && l.locale.length >= 2) {
          return lang === l.locale.substring(0, 2);
        }
        return false;
      });
      return locale[0];
    },
  },
};
</script>
<style lang="sass" scoped>
@import "bulma/sass/utilities/_all.sass"
@import 'bulma/sass/layout/section.sass'
@import 'bulma/sass/elements/container.sass'
@import "bulma/sass/grid/columns.sass"
@import 'bulma/sass/helpers/_all'
@import 'bulma/sass/elements/icon.sass'
</style>

<style>
p {
  margin-bottom: 10px;
  margin-top: 10px;
}
.is-horizontal-center {
  justify-content: center;
}

.is-white {
  color: white;
}
</style>
