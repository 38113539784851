<template>
  <div class="checkin">
    <h4>{{$t('components.LogDetailTypeCheckin.title', { name: poiName }) }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    poiName: {
      type: String,
      required: true,
    },
  },
};
</script>
