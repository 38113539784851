<template>
  <li class="location location--small">
    <div class="location__inner">
      <figure
        class="location__image"
        :class="imageColor"
      >
        <a
          class="js-toggle"
          href="#"
        >
          <span
            class="points location__points"
            :class="pincColorClass"
          >
            <strong> {{ item.score }}</strong> PTS
          </span>
          <img
            :src="iconFile"
            alt="icon"
          >
        </a>
      </figure>
      <div class="location__content">
        <h4 :class="titleClass">
          <i class="ico-pin-small" /> {{ upper(itemTitle) }} &nbsp;
        </h4>
        <p class="pad">
          <em>{{ itemTitleAndDate }}</em>
        </p>
      </div>
    </div>

    <button
      class="btn btn--details"
      @click="emit('open')"
    >
      {{ $t('components.TeamLogItem.details') }}
    </button>
  </li>
</template>
<script setup>
import {computed, inject } from 'vue'
import { parseISO, format } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import { poiTitle } from '../../translationTools/poi';
import { useConfigStore } from '../../pinia/configuration';

const emit = defineEmits(['open']);

const config = useConfigStore().appConfig;
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  pois: {
    type: Object,
    required: true,
  },
});
const iconFile = computed(() => `/themes/common/icons/icon-dashboard-${props.item.type}.svg`);

const imageColor = computed(() => {
  if (props.item.type === 'question' || props.item.type === 'photo') {
    return 'color-orange';
  }
  if (props.item.type === 'bonus') {
    return 'color-purple';
  }
  if (props.item.type === 'checkin') {
    return 'color-green';
  }
  return '';
});
const pincColorClass = computed(() => {
  if (props.item.score > 0) {
    return 'points--green';
  }
  return 'points--pink';
});

const itemTitle = computed(() => {
  if (props.item.type === 'question') {
    return upper(`${props.item.content.questionTitle}`);
  }
  if (props.item.type === 'photo') {
    return upper(`${$t('components.RewardItem.photo_type')} - ${props.item.content.questionTitle}`);
  }
  if (props.item.type === 'checkin') {
    return upper(`Checkin - ${poiName(props.item.poiId)}`);
  }
  if (props.item.type === 'bonus') {
    return upper(`Bonus - ${props.item.content.reason}`);
  }
  return '';
});

const titleClass = computed( ()=> {
  if (props.item.type === 'reward') {
    return 'location__yellow';
  }
  return '';
});


const itemTitleAndDate = computed(() => {
  let dateValid = false;
  const itemDate = parseISO(props.item.createdAt);
  if (itemDate !== 'Invalid Date') {
    dateValid = true;
  }
  if (dateValid === true) {
    let formatedDate = null;
    if (config.lang === 'fr') {
      formatedDate = format(itemDate, 'PPpp', { locale: fr });
    } else {
      formatedDate = format(itemDate, 'PPpp', { locale: enGB });
    }
    return `${poiName(props.item.poiId)} - ${formatedDate}`;
  }
  return poiName(props.item.poiId);
});

const upper = (value) => {
  return value.toUpperCase();
};

const poiName = (poiUUID) => {
  const p = props.pois[poiUUID];
  if (p) {
    return poiTitle(p, config.lang);
  }
  return '';
};
</script>

<style lang="scss">
.location__content {
    h4 {
      color: #222;
    }
  }
.btn--details {
  width: 70%;
  margin: 0 auto;
  margin-block-start:-25px;
  display:flex;
  justify-content: center;
  text-align:center;
  border: 1px solid #fff;
}
.pad {
  padding-bottom: 50px;
}
.location--small .location__image{
  border-radius: 50%;
  &.color-orange {
    background-color: #E59435;
  }
  &.color-green {
    background-color: #4caf50;
  }
  &.color-purple {
    background-color: #9c27b0;
  }
img {
  padding: 10px 10px;
}
}
</style>
