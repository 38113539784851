<template>
  <div :class="inverted ? 'u-append-line-small' : 'u-append-line'">
  <a
    class="button-back" :class="inverted ? 'inverted' : ''"
    href="#" v-on:click.prevent="$emit('click')">
    <img
      src="../../assets/images/icons/ico-arrow-left.svg"
      width="8"
      height="13"/>
    {{ title }}
  </a>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    event: {
      type: String,
      required: false,
      default: null,
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
