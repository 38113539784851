<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-content">
      <div class="team-log-modal location location--small">
        <h3>{{ title }}</h3>
        <span class="is-pulled-right points location__points points--green">
          <i class="ico-plus" />
          <strong> {{ log.score }}</strong>
          PTS
        </span>
        <log-detail-question
          v-if="log.type === 'question'"
          :expected-answers="log.content.expectedAnswers"
          :given-answers="log.content.givenAnswers"
        />
        <log-detail-checkin
          v-else-if="log.type === 'checkin'"
          :poi-name="poiName(log.poiId)"
        />
        <log-detail-photo
          v-else-if="log.type === 'photo'"
          :src="log.content.givenAnswers[0]"
          :expected-answer="log.content.expectedAnswers[0]"
        />
        <log-detail-bonus
          v-else-if="log.type === 'bonus'"
          :reason="log.content.reason"
        />
      </div>
    </div>
    <button
      class="btn btn--close"
      @click="$emit('close')"
    >
      {{ $t('components.LogDetail.btnClose') }}
    </button>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    />
  </div>
</template>

<script>

import LogDetailQuestion from './LogDetailTypeQuestion.vue';
import LogDetailCheckin from './LogDetailTypeCheckin.vue';
import LogDetailPhoto from './LogDetailTypePhoto.vue';
import LogDetailBonus from './LogDetailTypeBonus.vue';
import { poiTitle } from '../../translationTools/poi';
export default {
  components: {
    LogDetailQuestion,
    LogDetailCheckin,
    LogDetailPhoto,
    LogDetailBonus,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    log: {
      type: Object,
      required: true,
    },
    pois: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
  },
  computed: {
    title() {
      if (this.log.type === 'checkin') {
        return this.$t(
          'components.LogDetail.titleVisit',
          {
            value: this.poiName(this.log.poiId),
          },
        );
      }
      if (this.log.type === 'question') {
        return this.$t(
          'components.LogDetail.titleQuestion',
          {
            value: this.log.content.questionTitle,
          },
        );
      }
      if (this.log.type === 'photo') {
        return this.$t(
          'components.LogDetail.titlePhoto',
          {
            value: this.log.content.questionTitle,
          },
        );
      }
      if (this.log.type === 'bonus') {
        return this.$t('components.LogDetail.titleBonus');
      }
      return '';
    },
  },
  methods: {
    poiName(poiUUID) {
      const p = this.pois[poiUUID];
      if (p) {
        return poiTitle(p);
      }
      return this.$t('components.LogDetail.poiArchived');
    },
  },
};
</script>

<style lang="sass" scoped>
@import "bulma/bulma.sass"
</style>
<style lang="scss" scoped>
.modal-content {
  width: 80%;
  border-radius: 8px;
  background-color: white;
  margin-left: 30px;
  margin-right: 30px;
  padding: 20px 20px;
}
.btn--close {
  width: 70%;
  margin: 0 auto;
  margin-block-start:-25px;
}
 .team-log-modal {
    .points {
      color: #fff;
      strong {
        color:#fff;
      }
    }
    h3 {
      font-size: 16px;
      font-weight: 700;
      color: #123274;
      margin-bottom: 30px;
    }
    h4 {
      font-weight: 500;
      font-size: 14px;
      color: #123274;
      margin-bottom: 5px;
    }
    li {
      list-style: square;
      margin-left: 30px;
    }
    .question {
      padding-bottom: 10px;
    }

  }
</style>
