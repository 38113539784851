<template>
  <div class="question">
    <h4>{{ $t('components.LogDetailTypeQuestion.expectedAnswers') }}</h4>
    <p>
      <ul>
        <li v-for="a in expectedAnswers" :key="a">
          {{ a }}
        </li>
      </ul>
    </p>
    <h4>{{ $t('components.LogDetailTypeQuestion.givenAnswers') }}</h4>
    <p>
      <ul>
        <li v-for="b in givenAnswers" :key="b">
          {{ b }}
        </li>
      </ul>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    expectedAnswers: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    givenAnswers: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
};
</script>
