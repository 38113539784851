<template>
  <div class="question">
    <h4>{{ $t('components.LogDetailTypePhoto.expectedAnswer') }}</h4>
    <p>
      {{ expectedAnswer }}
    </p>
    <h4>{{ $t('components.LogDetailTypePhoto.givenAnswer')}}</h4>

    <responsive-image :picture-path="src" :base-width="1024"/>
    <a :href="src" target="_blank"> {{ $t('components.LogDetailTypePhoto.download')}}</a>
  </div>
</template>

<script>
import ResponsiveImage from '../image/ResponsiveImage.vue';

export default {
  components: { ResponsiveImage },
  props: {
    expectedAnswer: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },
};
</script>
