<template>
  <div class="question">
    <h4>{{ $t('components.LogDetailTypeBonus.title') }}</h4>
    <p>
      {{ reason }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    reason: {
      type: String,
      required: true,
    },
  },
};
</script>
